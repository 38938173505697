@use '../../styles/palette' as *;
@use '../../styles/forms' as *;

.button-row {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.title-input {
  @include important-input();
}

.link-row {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;

  button.link {
    background: none;
    color: $blue;
    padding: 3px;
    font-weight: normal;

    &:hover {
      color: $blue;
      text-decoration: underline;
      background: none;
    }
  }
}

.attention-message {
  border: 1px solid $coral;
  border-radius: 3px;
  padding: 15px;
  margin-top: 40px;
  color: $grey;

  .header {
    color: $coral !important;
  }
}

.cloning-message {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 2em 3em 0 3em;
}

.create-button {
  display: flex;
  justify-content: center;
}

.selected-dates:global(.ui.segment) {
  text-align: center;
  color: $coral !important;
  color: $white;
  transition: color 0.5s ease-out;

  &.active {
    color: $light-teal !important;
  }
}

.advanced-options,
.type-selection {
  margin-top: 40px;
  margin-bottom: 50px;
  border: 1px solid #bbe;
  border-radius: 3px;
  padding: 15px 15px 0 15px;

  .headerbar {
    display: flex;
    justify-content: space-between;
    color: #8c8ce6;

    :global(.ui.header) {
      color: #8c8ce6;
    }
  }

  .options {
    justify-content: space-between;
    margin: 1em 0 0 1em;
    color: #808080;
    padding-bottom: 15px;

    & > div {
      margin: 0 0.5em 1em 0;
    }
  }
}

.advanced-options {
  .headerbar {
    &:hover {
      cursor: pointer;
    }
  }

  .options {
    :global(.ui.radio.checkbox) > label {
      font-size: 16px;
      color: #808080;
    }

    .timeslot-options {
      margin-left: 0.6em;

      .radio {
        display: block;
        margin-bottom: 1em;
      }

      .date-input {
        margin-top: 1.5em;
        margin-left: 2.5em;

        span {
          margin-right: 1em;
        }
      }
    }
  }

  .advanced-checkbox {
    float: right;
  }
}

.type-selection {
  .options {
    & > div {
      display: flex;
      justify-content: center;
    }

    .mobile {
      flex-direction: column;
      gap: 1em;
      max-width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
