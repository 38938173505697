@use '../styles/palette.scss' as *;

.header {
  background: linear-gradient($coral, $pink);
  padding: 3em 3em 1em 3em;

  .title a img {
    max-height: 2.5em;
  }
}

@media screen and (max-width: 600px) {
  .header {
    background: linear-gradient($coral, $pink);
    padding: 3em 1em 1em;

    .title a img {
      max-height: 2.5em;
    }
  }
}
