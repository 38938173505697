@use '../../styles/palette' as *;
@use '../../styles/boxes' as *;

$max-width: 700px;

.container {
  max-width: $max-width;
  margin: auto;

  .box {
    @include box-peach();
    font-size: 1.2em;
    max-width: $max-width;
    padding: 1em;
    list-style: none;
    counter-reset: list-counter;
    margin-bottom: 1em;

    li {
      counter-increment: list-counter;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 1.5em;
      }

      img {
        flex-shrink: 0;
      }

      &::before {
        content: counter(list-counter);
        display: inline-block;
        flex-shrink: 0;
        width: 2em;
        height: 2em;
        background-color: $white;
        color: $pink;
        text-align: center;
        line-height: 2em;
        margin-right: 0.5em;
      }
    }
  }
}

.icon {
  max-width: 4em;
  max-height: 4em;
  vertical-align: middle;
  margin: 0 1em;
  fill: $purple;
}

.button {
  display: flex;
  justify-content: flex-end;

  :global(.ui.button) {
    margin-right: 0;
  }
}
