@use '../../styles/palette' as *;
@use '../../styles/forms' as *;
@use '../../styles/boxes' as *;

.message-container {
  max-width: 700px;
  margin: 0 auto 2em auto;
}

.grid-view {
  max-width: max-content;
  margin: 0 auto;
  padding-top: 1rem;

  .grid-view-name {
    padding: 14px;
  }

  .grid-view-flex {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 14px;

    .grid-view-calendar {
      margin-left: 14px;
      margin-right: 14px;
    }

    .grid-view-table {
      padding-left: 14px;
      padding-right: 14px;
      overflow-x: auto;
    }
  }
}

.options-msg {
  text-align: center;
}

.tab-pane:global(.ui.segment) {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.date:global(.ui.header) {
  text-align: center;
  padding-bottom: 5px;
  padding-top: 5px;

  &.date-selected {
    background-color: $turquoise;
    color: $white;
    border-radius: 3px;
  }
}

.hours-column {
  position: relative;
  width: 100%;
  height: 750px;
  margin-top: 35px;

  .hour {
    position: absolute;
    color: $grey;
    width: 25px;
    background-color: white;
    z-index: 1;
  }
}

.participant-name-box {
  @include box-grey();
  margin-bottom: 2em;
  width: 100%;

  .participant-name-input {
    @include important-input();
  }
}

.participant-title {
  font-weight: normal;
  color: $coral;

  :global(.icon) {
    color: lighten($grey, 30%);
  }
  .participant-name {
    vertical-align: middle;
  }
}

.options-column {
  position: relative;
  width: 100%;
  height: 750px;
  margin: 1px;

  .answer-slot {
    position: absolute;
    border-radius: 2px;
    z-index: 1;
    width: 100%;

    &.selectable {
      cursor: pointer;
    }

    &:global(.overlapping):hover {
      left: 0 !important;
      z-index: 999;
      width: 100% !important;
    }

    &.unavailable {
      color: $don-juan;
      background-color: #d9d9d9;
      border: 1px solid rgba($grey, 0.5);

      &:global(.overlapping):not(.taken):hover {
        background-color: rgba($grey, 0.9);
        color: $light-grey;
      }
    }

    &.available {
      color: $dark-green;
      background-color: #b0f9b6;
      border: 1px solid rgba($dark-green, 0.3);

      &:global(.overlapping):hover {
        background-color: rgba($green, 0.9);
      }
    }

    &.ifneedbe {
      color: $dark-yellow;
      background-color: #fcf4b5;
      border: 1px solid rgba($dark-yellow, 0.3);

      &:global(.overlapping):hover {
        background-color: rgba($yellow, 0.9);
      }
    }

    &.taken {
      background: repeating-linear-gradient(
        45deg,
        #f2f2f2,
        #f2f2f2 10px,
        #e4e4e4 10px,
        #e4e4e4 20px
      );
    }

    .option {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 3px;
      user-select: none;
      align-items: center;

      span {
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
      }

      i:global(.icon) {
        height: initial;
      }
    }
  }

  .multiple-answer-slot {
    position: absolute;
    border-radius: 2px;
    z-index: 1;
    width: 100%;
    border: 1px solid rgba($grey, 0.5);
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;

    .option {
      cursor: pointer;
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 3px;
      user-select: none;

      &.unavailable {
        background-color: #d9d9d9;
        color: $don-juan;
      }

      &.available {
        color: $dark-green;
        background-color: #b0f9b6;
      }

      &.ifneedbe {
        color: $dark-yellow;
        background-color: #fcf4b5;
      }

      &.taken {
        background: repeating-linear-gradient(
          45deg,
          #f2f2f2,
          #f2f2f2 10px,
          #e4e4e4 10px,
          #e4e4e4 20px
        );
      }

      i:global(.icon) {
        height: initial;
      }
    }
  }

  .busy-slot {
    position: absolute;
    background-color: $peach;
    border: 1px solid darken($peach, 20%);
    opacity: 0.3;
    width: 100%;
  }

  .hours-separator {
    position: absolute;
    border: 0;
    width: 114%;
    height: 1px;
    margin: 0;
    background-color: #e6e6e6;
    left: -7%;
  }
}

.all-options-checkbox:global(.ui.checkbox) label {
  font-size: 0.9em;
}

// .bottom-row {
//   padding-bottom: 3em;
// }

:global(.ui.grid) .bottom-row:global(.row) {
  margin-top: 4em !important; // Semantic sets ui.stackable.grid margins to zero
  justify-content: flex-end;
  align-items: baseline;

  .options-msg {
    transition: color 0.25s ease-out;
    color: $don-juan;
    margin-right: 1em;

    &:global(.none) {
      color: $grey;
    }
  }
}

@media screen and (max-width: 767px) {
  :global(.ui.grid) .bottom-row:global(.row) {
    flex-direction: column;
    align-items: center;

    .options-msg {
      margin-bottom: 1em;
    }
  }
}

.on-behalf {
  color: $purple;
}

.comment-submit {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .comment-submit {
    display: inline-block !important;
    text-align: center;
    width: unset;

    input {
      margin-bottom: 2em !important;
      width: 100%;
    }
  }
}

.newdle-timezone {
  color: lighten($grey, 10%);
  text-align: center;
  font-size: 0.9em;
  margin-top: 2px;

  .original-timezone {
    cursor: pointer;
    font-weight: bold;
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: inherit;
    font-size: inherit;
  }
}

.timezone-box {
  background-color: $sui-secondary-grey;
  padding: 1em;
  color: $grey;
  border-radius: 3px;
  border: 1px solid lighten($grey, 32%);
  font-size: 1.2em;
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  // same as semantic ui segment
  width: calc(100% - (-1px * 2));
  margin: 0 -1px;

  .icon {
    width: 1.5em;
    vertical-align: middle;
    margin-right: 5px;
    align-self: center;
  }

  .timezone-picker {
    align-self: center;
  }
}

// if there's no attached secondary segment we don't get its bottom-margin
// to keep the calendar and timezone box separate
:global(.ui.segment.top) + .timezone-box {
  margin-top: 1rem;
}
