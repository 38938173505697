@use '../../styles/palette' as *;

.container {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 2em 3em 0 3em;

  .header {
    color: $coral;
  }
}

.button-row {
  padding-top: 1em;
  display: flex;
  justify-content: center;

  .finalize-button {
    background-color: $light-teal;
    color: $white;
    font-size: 20px;

    &:hover {
      background-color: teal;
      color: $white;
    }
  }

  .dropdown-button {
    font-size: 20px;
  }

  .create-event-button {
    background-color: $dark-blue !important;
    color: $white !important;

    &:hover {
      background-color: darken($dark-blue, 10%) !important;
    }
  }
}

.missing-participants {
  max-width: 700px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 3em;

  .user-element {
    display: inline-flex;
    align-items: center;
    margin-right: 13px;
  }
}

.email-participant-list {
  :global(.ui.list) {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  & + .email-participant-list {
    margin-top: 1em;
  }
}

.mail-checkbox {
  margin-top: 20px;

  :global(.ui.input) {
    margin: 15px 25px 10px 25px;
    width: calc(100% - 50px);
  }

  .email-participant-list {
    margin: 10px 25px;
  }
}
