.participant-grid {
  margin-left: auto;
  margin-right: auto;
  padding: 2em 0 0 0;
}

.answer-grid {
  tr.selectable td div.busy-time {
    position: absolute;
    bottom: 0;
    border-top: 5px solid darken(#fff6f6, 20%);
  }

  tr.selectable td div.free-time {
    position: absolute;
    bottom: 0;
    border-top: 5px solid #8fffbc;
    left: 0;
    width: 100%;
  }

  tr.selectable {
    td:global(.selectable):not(:first-child) {
      cursor: pointer;
    }

    td:not(:first-child) {
      position: relative;
    }
  }

  tbody tr.spacer {
    height: 20px;
    background-color: #f9f9f9;
  }
}

.participant-grid,
.answer-grid {
  overflow-x: auto;
  max-width: max-content;

  td.avatar-cell {
    white-space: nowrap;
    padding-left: 1em;

    .name {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  td.highlighted {
    border-left: 2px solid #6101b3;
  }

  .comment-icon {
    position: relative;
    top: -17px;
    left: 5px;
    max-width: 0;
  }

  .avatar {
    padding-right: 6px;
  }

  .avatar img:global(.user-avatar) {
    border-radius: 50%;
  }

  .middle-aligned {
    display: table-cell;
    vertical-align: middle;
  }

  th div.date {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 6px;
  }

  th div.time {
    font-size: 14px;
    font-weight: normal;
    color: gray;
  }

  th div.timezone {
    font-size: 10px;
    font-weight: normal;
    color: gray;
    margin-bottom: 6px;
  }

  thead th.header {
    padding-bottom: 8px;
  }

  thead th.hover,
  tfoot th.hover {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.95);
  }

  thead th.active,
  tfoot th.active {
    background: #e0e0e0;
    color: rgba(0, 0, 0, 0.87);
  }

  thead th.pointer {
    cursor: pointer;
  }

  tbody tr td:global(.positive).hover {
    background: darken(#fcfff5, 5%) !important;
  }

  tbody tr td:global(.negative).hover {
    background: darken(#fff6f6, 3%) !important;
  }

  tbody tr td:not(:global(.negative)):not(:global(.positive)).hover {
    background: rgba(109, 74, 74, 0.03);
  }

  tbody tr td:global(.positive).active {
    background: darken(#fcfff5, 8%) !important;
  }

  tbody tr td:global(.negative).active {
    background: darken(#fff6f6, 6%) !important;
  }

  tbody tr td:not(:global(.negative)):not(:global(.positive)).active {
    background: rgba(0, 0, 0, 0.04);
  }

  tbody tr td.finalized {
    opacity: 0.3;
  }

  table:global(.ui) tfoot tr th {
    padding: 0 0 0 0;
  }
}
