.calendar:global(.ui.segment) {
  padding: 0;
  min-height: 340px;

  :global(.CalendarMonth_table) {
    margin-top: 10px;

    :global(.CalendarDay) {
      border: none;
    }

    :global(.CalendarDay__highlighted_calendar),
    :global(.CalendarDay__selected),
    :global(.CalendarDay__default):hover {
      border-radius: 50%;
    }
  }

  :global(.DayPickerNavigation_button__verticalDefault) {
    box-shadow: none;
  }
  :global(.DayPicker__horizontal) {
    margin: auto;
  }
}
