.participant-table {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 2em 3em 0 3em;

  .participant-row {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;

    &:not(.finalized):hover {
      cursor: pointer;
    }

    :global(.ui.checkbox),
    :global(.ui.checkbox) label::before {
      width: 30px;
      height: 30px;
    }

    :global(.ui.checkbox) label::after {
      width: 24px;
      height: 24px;
      top: 4px;
      left: 3px;
    }

    .date {
      font-size: 25px;
      font-weight: normal;
      margin-bottom: 10px;
    }

    .time {
      font-size: 14px;
      font-weight: normal;
      color: gray;
    }

    .timezone {
      font-size: 10px;
      font-weight: normal;
      color: gray;
    }

    .available-participants {
      .wrapper {
        display: flex;
        align-items: center;
        padding: 0.5em 0;

        .availability-indicator {
          flex: 1;
          margin-right: 20px;
        }

        .participants {
          flex: 5;

          .user-element {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 5px 0;
            margin-right: 5px;
          }

          .more-participants {
            display: block;
            max-width: 90px;
            margin: 5px 0;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .count {
        margin-bottom: 15px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .participant-table {
    .participant-row {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      .availability-box {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }

      .available-participants {
        .wrapper {
          display: flex;
          justify-content: center;

          .participants {
            flex: 0 1 auto;
          }
        }
      }
    }
  }
}

.comment {
  font-size: 90%;
  margin-top: 0.5rem;
  color: gray;
  flex-basis: 100%;
}
