.result-item:hover {
  cursor: default !important;

  :global(.icon.plus) {
    cursor: pointer;
  }
}

.avatar-label {
  vertical-align: middle;
}
