.dropdown:global(.ui.dropdown) {
  white-space: nowrap;

  input {
    font-size: 1em;
  }

  :global(.menu > .item) {
    display: flex;
    flex-direction: column-reverse;

    :global(.description) {
      margin: 0.2em 0 0 0;
    }
  }
}

// Center the dropdown menu on mobile to make sure
// it fits in the screen
@media screen and (max-width: 600px) {
  .dropdown:global(.ui.dropdown .visible.menu) {
    left: 50%;
    transform: translate(-50%, 0);
  }
}
