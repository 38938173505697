@use '../../styles/palette' as *;

.newdle-title {
  display: flex;
  justify-content: center;
  color: $purple !important;
}

// uses same input style as semanticUI
.newdle-link {
  display: flex;
  justify-content: center;

  input {
    text-align: center !important;
  }
}

.success-message {
  border: 1px solid $light-teal;
  border-radius: 3px;
  padding: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
  color: $grey;

  .header {
    color: $light-teal !important;
  }
}

.summary-button {
  display: flex;
  justify-content: center;
}
