$coral: #ff8383;
$pink: #ff92c3;
$purple: #6101b3;
$grey: #808080;
$light-grey: #eee;
$sui-secondary-grey: #f3f4f5;
$white: #fff;
$peach: #ffb3b8;
$green: #62f46f;
$light-teal: #00caa8;
$turquoise: #00a699;
$dark-green: #005b4f;
$blue: #2185d0;
$dark-blue: #00394a;
$don-juan: #60495a;
$burgundy: #781f41;
$yellow: #f9e96d;
$dark-yellow: #8e6d3f;
