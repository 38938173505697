.user-search-container {
  min-width: 600px;
  width: min-content;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    min-width: unset;
    width: 100%;
  }

  .participant-list-item {
    &:hover {
      cursor: default !important;
    }

    .remove-icon:hover {
      cursor: pointer;
    }
  }
}

.participant-avatar img:global(.user-avatar) {
  border-radius: 50%;
}

.user-search-modal .user-search-modal-header {
  display: flex !important;
  align-items: center;

  :global(.ui.label) {
    margin-left: 1em;
  }
}
