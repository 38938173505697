.user-menu {
  display: flex;
  margin-right: 0;

  .user-gravatar {
    color: white;
    display: inline-flex;
    align-items: center;
    margin-right: 0.25em;

    img {
      margin-left: 10px;
      border-radius: 50%;
    }
  }
}

.unknown-user {
  color: white;
  cursor: pointer;

  i:global(.icon) {
    margin-left: 10px;
  }
}

.login-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
}
