@use '../styles/palette' as *;

@mixin box {
  background: white;
  margin: auto;
  padding: 1em;

  h3 {
    color: $pink;
    font-size: 1.3em;
  }

  p {
    color: $grey;

    strong {
      color: $purple;
    }
  }
}

@mixin box-peach {
  @include box();
  background-color: lighten($peach, 10%);
  color: $don-juan;
}

@mixin box-grey {
  @include box();
  background-color: lighten($light-grey, 4%);
  color: $don-juan;

  h3 {
    color: $purple;
  }
}
