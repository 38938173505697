.error-message {
  max-width: 500px;
  min-width: 200px;
  position: absolute !important;
  right: 50px;
  top: 90%;
  z-index: 9999;

  :global(.content) {
    margin-right: 30px;
  }
}
