@use '../../styles/palette' as *;
@use '../../styles/boxes' as *;

.step-box {
  list-style-type: none;
  padding-left: 0;
  margin: 0 0 0.5em 0;
  display: flex;
  align-items: flex-end;
}

.step {
  display: inline-block;
  margin: 0em 0.25em 0em 0em;
  background: white;
  border: none;
  border-radius: 0;
  color: $pink;
  transition: background 0.25s ease-in, transform 0.25s ease-in, width 0.25s ease-in;
  user-select: none;
  width: 3em;
  height: 3em;
  line-height: 3em;
  text-align: center;
  font-size: 1.1em;

  &.active {
    background: $purple;
    color: white;
    border: none;
    border-radius: 0;
    transform: translate(0, -0.2em);
    width: 2.4em;
    height: 2.4em;
    line-height: 2.4em;
    font-size: 1.5em;
  }

  &.clickable {
    cursor: pointer;
  }
}

.step-body {
  @include box();

  .description {
    color: $grey;
  }
}
